import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Typography, Button, Divider } from "@material-ui/core"
import logo from "./../../images/logo.png"
import LinkedinIcon from "@material-ui/icons/LinkedIn"
import { Link } from "react-router-dom"

export const DealFooter = () => {
  return (
    <>
      <div className="deal-footer suscribe-foot">
        <Container>
          <Row
            className="pt-5 pb-5 pt-5 text-center"
          >
            <Col className="my-auto">
              <h4>
                Si aún no es cliente abra su cuenta de inversión{" "}
                <Link to="https://app.dealfs.com.ar/dfs/solicitud-cuenta.registro">
                  {" "}
                  <Button className="btn-deal ml-3">Abrir cuenta</Button>
                </Link>
              </h4>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="deal-footer regular-foot">
        <Container>
          <Row className="pt-5 pb-5">
            <Col xs={12} sm={6} md={3}>
              <img src={logo} alt="logo" className="mb-3" />
              <Typography>Carlos Pellegrini 989, Piso 10. CABA</Typography>
              <Typography paragraph={true}>
                1009 ABS, Buenos Aires, Argentina
              </Typography>
            </Col>

            <Col xs={12} sm={6} md={3}>
                <h5>Páginas</h5>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/servicios/asset-management">Servicios</Link></li>
                    <li><Link to="/nuestrosproductos">Productos</Link></li>
                    <li><Link to="/nosotros">Nosotros</Link></li>
                    <li><Link to="/contacto">Contacto</Link></li>
                    <li><Link to="/comisiones">Comisiones</Link></li>
                </ul>
            </Col>

            <Col xs={12} sm={3}>
              <Typography paragraph={true}>Mi cuenta</Typography>
              <ul>
                <li>
                  <a href={"https://app.dealfs.com.ar/dfs/solicitud-cuenta.registro"}>Abrir Cuenta</a>
                </li>
                <li>
                  <a href={"https://deal.aunesa.com/Irmo/"}>Ingresar</a>
                </li>
                <li>
                  <a href={"https://anima.dealfs.com.ar/"}>Operar</a>
                </li>
              </ul>
            </Col>


            <Col xs={12} sm={6} md={3}>
                <h5>Contacto</h5>
                <ul>
                    <li>+54 11 5275 6390</li>
                    <li><Link to="/contacto">contacto@dealfs.com.ar</Link></li>
                </ul>
                <Divider />
                <Typography paragraph={true}>
                    Seguinos en:
                </Typography>
                <a href="https://www.linkedin.com/in/deal-financial-services-848943184" target="_blank" rel="noreferrer"> 
                    <LinkedinIcon />
                </a>
            </Col>
            <Typography variant="h1" style={{ fontSize: '10px' }}>
            Deal S.A. es un Agente de Liquidación y Compensación y Agente de Negociación Propio registrado bajo el número 524 y Agente de Colocación y Distribución Integral de Fondos Comunes de Inversión registrado bajo el número 133 ante la Comisión Nacional de Valores. Miembro de Bolsas y Mercados Argentinos S.A (BYMA), Mercado Argentino de Valores (MAV) y Rofex.
            </Typography>
            <Typography variant="h1" style={{ fontSize: '10px' }}>
            Este sitio y su contenido forman parte de la propiedad intelectual de Deal S.A.
            </Typography>
          </Row>
        </Container>
      </div>
    </>
  )
}
