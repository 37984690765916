import React, { useRef, useState } from 'react';
import emailjs from "emailjs-com"

import { Container, Row, Col } from "react-bootstrap"
import { Typography, Button } from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import PhoneIcon from "@material-ui/icons/Phone"
import minibar from "../../../../images/minibar.png"

export const Contacto = () => {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [asunto, setAsunto] = useState('');

  const [validName, setValidName] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [validAsunto, setValidAsunto] = useState(true);

  const[emailSent, setEmailSent] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    let proceed = 1;

    if ( asunto.length === 0 ) {
      setValidAsunto(false)
      proceed = 0
    }
    if ( name.length === 0 ) {
      setValidName(false)
      proceed = 0
    }
    if ( isValidEmail(email) === false ) {
      setValidEmail(false)
      proceed = 0
    }

    if (proceed === 0 ) {
      return
    }

    emailjs.sendForm('service_9hu9pfk', 'template_ikd68x9', form.current, 'ckUZl75YOGYrBKCoH')
      .then((result) => {
          setEmailSent(true)
      }, (error) => {
          console.log(error.text);
      });
  };

  function isValidEmail(val) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    }
  }

  return (
    <Container id="contacto" className="mb-5">
      <Row className="mt-5 pt-5 text-center">
        <Col>
          <Typography className="dfs-title" paragraph={true}>
            Contáctese con nuestro equipo
          </Typography>
          <img src={minibar} alt=""></img>
        </Col>
      </Row>

      <Row className="mt-5">
        <Col className="my-auto" xs={12} sm={6}>
          <Row>
            <Col className="text-center" xs={4}>
              <LocationOnIcon style={{ fontSize: "3rem" }} />
            </Col>
            <Col className="my-auto" xs={8}>
              <Typography>Carlos Pellegrini 989, piso 10. CABA 1009</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="text-center" xs={4}>
              <MailOutlineIcon style={{ fontSize: "3rem" }}></MailOutlineIcon>
            </Col>
            <Col className="my-auto" xs={8}>
              <Typography>contacto@dealfs.com.ar</Typography>
            </Col>
          </Row>
          <Row>
            <Col className="text-center" xs={4}>
              <PhoneIcon style={{ fontSize: "3rem" }}></PhoneIcon>
            </Col>
            <Col className="my-auto" xs={8}>
              <Typography>+54 11 5275 6390</Typography>
            </Col>
          </Row>
        </Col>
        <Col className="my-auto pt-4" xs={12} sm={6}>
          <form onSubmit={sendEmail} ref={form} autoComplete="off" className="contact-form">
            <input type="text" name="name" placeholder="Nombre*" value={name} onChange={e => { setName(e.target.value); setValidName(true) }} className={(validName ? 'half-input' : 'half-input empty-field')} />
            <input type="email" name="email" placeholder="Email*" value={email} onChange={e => { setEmail(e.target.value); setValidEmail(true) }} className={(validEmail ? 'half-input' : 'half-input empty-field')} />
            <input type="text" name="asunto" placeholder="Asunto*" value={asunto} onChange={e => { setAsunto(e.target.value); setValidAsunto(true) }} className={(validAsunto ? '' : 'empty-field')} />
            <textarea name="message" placeholder="Mensaje" />

            <Button type="submit" className="btn-deal" value="Enviar">
              Enviar consulta
            </Button>

            {emailSent
              ? <div className="form-notice">El mensaje ha sido enviado con exito</div>
              : <></>
            }
          </form>
        </Col>
      </Row>
    </Container>
  )
};

