const curriculumsMock = [
  {
    nombre: "Gaston Adhemar",
    puesto: "Presidente",
    cargos: [
      "Head of Sales Banco Hipotecario SA",
      "VP Barclays Wealth",
      "Director Comercial de Criteria Asset Management",
      "Deutsche Bank Head of Sales",
      "BankBoston (BA, Boston) Assistant VP"
    ],
    bio: "",
    link: "https://www.linkedin.com/in/gaston-adhemar-1480554",
    imagen: "./images/gaston.jpg",
  },
  {
    nombre: "Federico Mayol",
    puesto: "Vicepresidente",
    cargos: [
      "Presidente Standard Investments",
      "Head Financial Advisor International Private Banking Banco de Boston",
      "Gerente Financiero Deutsch Bank",
      "Head Porfolio Manager y Director de Fondos 1784"
    ],
    bio: "",
    link: "https://www.linkedin.com/in/federico-mayol-77b07099/",
    imagen:
    "./images/federico.jpg",
  },
  {
    nombre: "Ligia Woskressenski",
    puesto: "Directora",
    cargos: [
      "CFO Grupo Zurich",
      "Chief Investment Officer de Consultatio Asset Management"
    ],
    bio: "",
    link: "https://www.linkedin.com/in/ligia-woskressenski-82b3a2129/",
    imagen: "./images/ligia.jpg",  
  },
  {
    nombre: "Tomás Mountford",
    puesto: "Director - Trading",
    cargos: [
      "Director de Criteria Investors",
      "Head Trader - Deutsche Bank Argentina",
      "Head Trader y CFO - ExPrinter Banco"
    ],
    bio: "",
    link: "https://www.linkedin.com/in/tom%C3%A1s-mountford-702bb736/",
    imagen: "./images/tomas.jpg",
  },
]

export default curriculumsMock
