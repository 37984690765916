import React from 'react'
import { Contacto } from '../Components/Home/Body/Contacto'

export const ContactoPage = () => {
    return (
        <>
            <Contacto />
        </>
    )
}
