import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import {Link} from 'react-router-dom'


export const NuestrosProductos = () => {

    return (
        <section>
            <Container>
                <Row className="text-center">
                    <Col>
                        <h2 className="dfs-title" paragraph={true}>Nuestros <b>Productos</b></h2>
                    </Col>
                </Row>
                
                <Row className="text-center">
                    <Col className="products-wrapper">
                        <ul>
                            <li><Link to="/instrumentos/cauciones">Cauciones</Link></li>
                            <li><Link to="/instrumentos/chpd">Cheques de Pago Diferido</Link></li>
                            <li><Link to="/instrumentos/letras">Letras</Link></li>
                            <li><Link to="/instrumentos/bonos">Bonos</Link></li>
                        </ul>
                        <ul>
                            <li><Link to="/instrumentos/acciones">Acciones</Link></li>
                            <li><Link to="/instrumentos/cedears">Cedears</Link></li>
                            <li><Link to="/instrumentos/pagare-bursatil">Pagaré burstatil</Link></li>
                        </ul>
                        <ul>
                            <li><span>Emisiones Primarias</span></li>
                            <li><Link to="/instrumentos/fci">Fondos Comunes de Inversión</Link></li>
                        </ul>
                    </Col>
                </Row>

            </Container>
        </section>
    )

}
