import React from 'react';


export default function InfoItems(props) {
  const { title, body } = props

  return (
    <div className="info-item" >          
      {
        title ? 
        <h3>
          {title}
        </h3>
      :
        <></>
      }
      {
        body ? 
        <p>
          {body}
        </p>
      :
        <></>
      }
    </div>
  );
}