import React from "react"
import { Typography, Box } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import {
  CoberturaTipoCambioMock,
  CoberturaInflacionMock,
  RentaInternacionalMock,
  PYMEMock,
} from "../../__mocks__/rentaFija"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import RentaFijaAccordion from "../elements/rentaFija/RentaFijaAccordion"

export default function RentaFija() {
  return (
    <section className="inner-page-section">
      <Container>
        <Row>
          <Col>
            <div className="regular-page-header">
              <h4>Servicios / <Link to="/servicios/asset-management">Asset Management</Link> / <Link to="/servicios/fondos">Fondos</Link></h4>
              <h1>Renta Fija</h1>
            </div>

            <Typography className="blue-text" paragraph="true">
              Familia de Fondos que usted puede elegir.
            </Typography>
            <Typography paragraph="true">
              Elija el fondo que se ajuste a su perfíl de inversor teniendo en
              cuenta el tipo de moneda, instrumentos, horizonte de inversión y
              tiempo de rescate.
            </Typography>

            <Box mb={3} pt={4}>
              {CoberturaTipoCambioMock.map((x) => (
                <>
                  <Typography className="blue-text" paragraph="true">
                    {x.title}
                  </Typography>
                  <RentaFijaAccordion rows={x.rows} />
                </>
              ))}
            </Box>

            <hr />

            <Box mb={3}>
              {CoberturaInflacionMock.map((x) => (
                <>
                  <Typography className="blue-text" paragraph="true">
                    {x.title}
                  </Typography>
                  <RentaFijaAccordion rows={x.rows} />
                </>
              ))}
            </Box>

            <hr />

            <Box mb={3}>
              {RentaInternacionalMock.map((x) => (
                <>
                  <Typography className="blue-text" paragraph="true">
                    {x.title}
                  </Typography>
                  <RentaFijaAccordion rows={x.rows} />
                </>
              ))}
            </Box>

            <hr />

            <Box mb={3}>
              {PYMEMock.map((x) => (
                <>
                  <Typography className="blue-text" paragraph="true">
                    {x.title}
                  </Typography>
                  <RentaFijaAccordion rows={x.rows} />
                </>
              ))}
            </Box>
          </Col>
        </Row>
        <Row className="return-btn-row">
          <Col>
            <Link to="/servicios/fondos"><Button className="btn-deal btn-deal-blue">Volver a Fondos</Button></Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
