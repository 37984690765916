import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom'
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import MenuIcon from '@mui/icons-material/Menu';
import menuMock from '../../../__mocks__/menu';

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function MobileButtons() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
      >
        <MenuIcon />
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {
          menuMock.map(x => {
            return <>
              <Link to={x.link} className="inline menu-subitem"><MenuItem onClick={handleClose} disableRipple><LabelImportantIcon /> {x.title}</MenuItem></Link>
              {

                x.subItems.length > 1 ? x.subItems.map(y => {
                  if (y.link.includes("http"))
                    return <MenuItem onClick={handleClose}><a href={y.link} target="_blank" className="menu-subitem" rel="noreferrer"><LabelImportantIcon />{y.title}</a> </MenuItem>
                  else
                    return <MenuItem onClick={handleClose}><Link to={y.link} className="menu-subitem"><LabelImportantIcon /> {y.title} </Link></MenuItem>
                })
                  :
                  <>
                  </>
              }
            </>
          })
        }
        <MenuItem onClick={handleClose}><a href={"https://deal.aunesa.com/Irmo/"} target="_blank" className="menu-subitem" rel="noreferrer"><LabelImportantIcon />Ingresar</a> </MenuItem>
        <MenuItem onClick={handleClose}><a href={"https://anima.dealfs.com.ar/"} target="_blank" className="menu-subitem" rel="noreferrer"><LabelImportantIcon />Operar</a> </MenuItem>
        <MenuItem onClick={handleClose}><a href={"https://app.dealfs.com.ar/dfs/solicitud-cuenta.registro"} target="_blank" className="menu-subitem" rel="noreferrer"><LabelImportantIcon />Abrir cuenta</a> </MenuItem>
      </StyledMenu>
    </div>
  );
}
