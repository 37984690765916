
export const PYMEMock = [
    {
        title:"PYME", 
        rows: 
            [
                {
                    title: "Fondo CMA PYME – DFS Asesor de Inversiones",
                    fondo: "Fondo CMA PYME – DFS Asesor de Inversiones",
                    tipo: "PYMEs",
                    plazo: "7 días hábiles",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Obtener una apreciación de su valor patrimonial mediante la conformación de un portafolio de inversiones vinculadas con el financiamiento de PYMES.",
                    cartera: "Instrumentos de renta fija de corto plazo públicos y privados, cheques de pago diferido, Fideicomisos Financieros, Plazo Fijos, Cauciones.",
                    agenteAdministracion: "CMA Asset Management",
                    agenteCustodia: "Banco de Valores",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EepIm-8QRhNKsQiWseP4zgEBBZpno-LjqRI2GjMY_ZTcyQ?e=lmpHka",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/Ebpkp6xkDAFLqdq1VOvEhX0BpxQo2PZwWoqJL3_jS3itEQ?e=7ogh8V"

                }
            ]
    }
]
export const RentaInternacionalMock = [
    {
        title:"Renta Internacional", 
        rows: 
            [
                {
                    title: "Fondo CMA Renta Dólar – DFS Asesor de Inversiones",
                    fondo: "Fondo CMA Renta Dólar – DFS Asesor de Inversiones",
                    tipo: "Dólar",
                    plazo: "48hs",
                    moneda: "USD MEP",
                    perfilInversor:"Moderado",
                    inversionMinima: "US$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Su objetivo es proteger el capital y obtener una renta en dólares con un horizonte de inversión medio y liquidez diaria.",
                    cartera: "Créditos soberanos y corporativos de latinoamérica y USA y hasta 50% en bonos corporativos de Argentina",
                    agenteAdministracion: "CMA Asset Management",
                    agenteCustodia: "Banco de Valores",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/ESIqk2ohSLhJsid1kOquUsgBhVPM1u4XFNUF7ri0B-OjSg?e=zzXEzf",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/Eftzb6ov4F1Djbz1FYRs0tgB8nuIoyxZm7a5iEmqxSirZA?e=hZXTUk"

                }
            ]
    }
]

export const CoberturaInflacionMock = [
    {
        title:"Cobertura Inflación", 
        rows: 
            [
                {
                    title: "Fondo Balanz Institucional",
                    fondo: "Fondo Balanz Institucional",
                    tipo: "CER",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Obtener rendimientos similares o superiores a la inflación en el mediano plazo. Ideal para inversores que busquen rendimientos variables que sigan a la inflación y que tengan un horizonte de inversión de mediano plazo.",
                    cartera: "Títulos públicos y privados ajustados por CER y/o UVA",
                    agenteAdministracion: "Balanz Soc Gerente",
                    agenteCustodia: "Banco de Valores",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EfvZhr626ENEmaqZFaUprdwBOs-Bbvf_qRFJR4IhyXD5Dg?e=oDxswA",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EfvZhr626ENEmaqZFaUprdwBOs-Bbvf_qRFJR4IhyXD5Dg?e=1NMyzw"

                }
            ]
    }
]

export const CoberturaTipoCambioMock = [
    {
        title:"Cobertura Tipo de Cambio Oficial", 
        rows: 
            [
                {
                    title: "CMA Performance",
                    fondo: "CMA Performance",
                    tipo: "Dollar Linked",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Obtener un desempeño similar al tipo de cambio oficial. El fondo invierte en una cartera diversificada de títulos públicos y privados que ajusten su capital por la evolución del tipo de cambio oficial, o bien realizando cobertura de las inversiones en pesos a través de futuros de tipo de cambio. Si bien se busca que el fondo tenga una alta correlación con el tipo de cambio, puede no representar una cobertura perfecta.",
                    cartera: "Títulos públicos y privados con cláusula de ajuste al tipo de cambio oficial así como en títulos en pesos de corto plazo con cobertura de futuros de tipo de cambio.",
                    agenteAdministracion: "CMA Asset Management",
                    agenteCustodia: "Banco Comafi",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EelPDy-euZVEuIqZ0eng1coBlj3mIjVpRlCo7EMpU1Z4vg?e=TOVffZ",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EcGKtgdH6dZEpaYFvfmFqZgB2hlGdixsXOPurHIEO6Yh1Q?e=Fq7gcF"

                },
                {
                    title: "IAM Renta Balanceada",
                    fondo: "IAM Renta Balanceada",
                    tipo: "Dollar Linked",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Obtener un desempeño similar al tipo de cambio oficial. El fondo invierte en una cartera diversificada de títulos públicos y privados que ajusten su capital por la evolución del tipo de cambio oficial, o bien realizando cobertura de las inversiones en pesos a través de futuros de tipo de cambio. Si bien se busca que el fondo tenga una alta correlación con el tipo de cambio, puede no representar una cobertura perfecta.",
                    cartera: "Títulos públicos y privados con cláusula de ajuste al tipo de cambio oficial así como en títulos en pesos de corto plazo con cobertura de futuros de tipo de cambio.",
                    agenteAdministracion: "Industrial Asset Management",
                    agenteCustodia: "Banco Industrial",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EfekZ9FsSplMqqh3wjJkir4BOemFUxOa1LgHZTfAQqXlpA?e=DuWHB9",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EdxH_8q3O0dItbuh9dfa8CEB-9oYDPbbJ5ENoI9ezux84A?e=saHNOu"
                },
                {
                    title: "Galileo Argentina",
                    fondo: "Galileo Argentina",
                    tipo: "Dollar Linked",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Obtener un desempeño similar al tipo de cambio oficial. El fondo invierte en una cartera diversificada de títulos privados que ajusten su capital por la evolución del tipo de cambio oficial, o bien realizando cobertura de las inversiones en pesos a través de futuros de tipo de cambio. Si bien se busca que el fondo tenga una alta correlación con el tipo de cambio, puede no representar una cobertura perfecta.",
                    cartera: "Títulos privados con cláusula de ajuste al tipo de cambio oficial así como en títulos en pesos de corto plazo con cobertura de futuros de tipo de cambio.",
                    agenteAdministracion: "Galileo Sociedad Gerente",
                    agenteCustodia: "Banco Comafi",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EfZ3ieDpYgBNjWH6f_BgSaoB2rLJx1sAjDC8sXtG5RCw9g?e=R1rv7o",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/ESYT0lYZ8GFHrwBKsGSZpLcBBmASiBxpLszo7eVWv44TQw?e=sYUWqU"
                },
                
            ]
    }
]
