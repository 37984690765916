import * as React from 'react';
import { Table, TableBody, TableRow, TableCell, TableContainer, TableHead } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {Row} from 'react-bootstrap'

export default function FondoDetalleTable ( props ) {
  const { data } = props

  return (
    <Row>
    <TableContainer className="bg-grey mt-5">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow className="blue-text">
            <TableCell>Objetivo</TableCell>
            <TableCell>Cartera</TableCell>
            <TableCell>Agente de administración</TableCell>
            <TableCell>Agente de custodia</TableCell>
            <TableCell style={{minWidth: "145px"}}>Descargar Informe y RG</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data ? 
            
            <TableRow
              key={data.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>{ data.objetivo }</TableCell>
              <TableCell>{ data.cartera }</TableCell>
              <TableCell>{ data.agenteAdministracion }</TableCell>
              <TableCell>{ data.agenteCustodia }</TableCell>
              <TableCell>
                <div className="download-holder">
                  <a href={ data.linkRG } className="download-btn" target="_blank" rel="noreferrer">RG <DownloadIcon /></a>
                  <a href={ data.linkInforme } className="download-btn" target="_blank" rel="noreferrer">FactSheet <DownloadIcon /></a>
                </div>
              </TableCell>

            </TableRow>
            : 
            <></>
          }
        </TableBody>
      </Table>
    </TableContainer>
    </Row>
  );
}