import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Link } from 'react-router-dom'
import Button from "@material-ui/core/Button"
import { SimpleAccordion, SimpleCard } from "../elements"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import ViewModuleIcon from "@material-ui/icons/ViewModule"
import {
  CoorporativasMock,
  InstitucionalesMock,
  HighNetworthIndividualsMock,
} from "../../__mocks__/administracionDeCarteras"

export default function AdministracionDeCarteras() {
  return (
    <section className="inner-page-section">
      <Container>
        <Row>
          <Col>
            <div className="regular-page-header">
              <h4>Servicios / <Link to="/servicios/asset-management">Asset Management</Link></h4>
              <h1>Administración de Carteras</h1>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Box mb={3}>
          <SimpleCard>
            <Typography variant="h5" className="light">
              <ViewModuleIcon color={"info"} fontSize={"large"} /> Coorporativas
            </Typography>
          </SimpleCard>
          <Box mt={2}>
            {CoorporativasMock.map((x, i) => {
              return <SimpleAccordion key={i} title={x.title} rows={x.rows} />
            })}
          </Box>
        </Box>

        <Box mb={3}>
          <SimpleCard>
            <Typography variant="h5">
              <ViewModuleIcon color={"info"} fontSize={"large"} />{" "}
              Institucionales
            </Typography>
          </SimpleCard>
          <Box mt={2}>
            {InstitucionalesMock.map((x) => {
              return <SimpleAccordion title={x.title} rows={x.rows} />
            })}
          </Box>
        </Box>

        <Box mb={3}>
          <SimpleCard>
            <Typography variant="h5">
              <ViewModuleIcon color={"info"} fontSize={"large"} /> High Networth
              Individuals
            </Typography>
          </SimpleCard>
          <Box mt={2}>
            {HighNetworthIndividualsMock.map((x) => {
              return <SimpleAccordion title={x.title} rows={x.rows} />
            })}
          </Box>
        </Box>

        <Row className="return-btn-row">
          <Col>
            <Link to="/servicios/asset-management"><Button className="btn-deal btn-deal-blue">Volver a Asset Management</Button></Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
