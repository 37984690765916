import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import { InfoItems } from '../../../elements'
import { Link } from 'react-router-dom'


export const Servicios = () => {
    return (
        <section className="bg-grey">
            <Container>
                <Row className="text-center">
                    <Col>
                        <h2 className="dfs-title" paragraph={true}>Estos son algunos de los <b>Servicios</b> que tenemos para usted.</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="info-items">
                        <InfoItems title="Asesoramiento Personalizado"
                body="Acompañamos a nuestros clientes a través de un asesoramiento financiero profesional, sólido y transparente, identificando las mejores oportunidades de inversión con el objetivo de mantener y acrecentar sus ahorros."></InfoItems>
                        <InfoItems title="Asset Management"
                body="Seleccionamos y gestionamos las mejores alternativas de inversión para obtener los retornos esperados acorde a sus objetivos e intereses."></InfoItems>
                        <InfoItems title="Sales & Trading"
                body="Nuestro equipo experimentado y de excelencia ejecutará, de manera eficiente, sus operaciones en el mercado."></InfoItems>
                        <InfoItems title="Financiamiento para empresas"
                body="Trabajamos con ustedes para lograr la mejor alternativa de financiamiento para potenciar el crecimiento de su empresa."></InfoItems>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <Link to="/contacto">
                            <Button className="btn-deal">Contáctenos</Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
 
    )
}
