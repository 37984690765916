import React from 'react'
import { Contacto } from '../Components/Home/Body/Contacto';
import { Cards } from './../Components/NuestrosProductos/Cards'
import { Container, Row, Col } from "react-bootstrap"

export const NuestrosProductosPage = () => {
    return (
        <section className="inner-page-section">
            <Container>
                <Row>
                    <Col>
                        <div className="regular-page-header">
                            <h1>Nuestros <b>Productos</b></h1>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Cards />

            <Contacto />
        </section>
    )
}
