import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Button } from '@material-ui/core'
import { InfoItems } from '../../../elements'
import { Link } from 'react-router-dom'

export const Beneficios = () => {
    return (
        <section className="info-items-section blue-back">
            <Container>
                <Row>
                    <Col className="text-center">
                        <h2 className="dfs-title"><b>Beneficios</b> de operar con nosotros</h2>
                    </Col>
                </Row>
                <Row>
                    <Col className="info-items">
                        <InfoItems title="Soluciones integrales" body="Ayudamos a diseñar una estrategia financiera adaptada a sus necesidades, teniendo en consideración sus objetivos, compromisos y restricciones particulares."></InfoItems>
                        <InfoItems title="Optimización impositiva" body="Ponemos especial foco en la optimización impositiva a la hora de la toma de decisiones."></InfoItems>
                        <InfoItems title="Gestión de riesgo" body="Análisis exhaustivo del entorno político y financiero para focalizarnos en mitigar riesgos inherentes a sus necesidades, tanto de financiamiento como de inversión."></InfoItems>
                        <InfoItems title="Inversiones responsables" body="Promovemos buenas prácticas ambientales, sociales y de gobierno corporativo con un fuerte compromiso en la ética y la transparencia."></InfoItems>
                        <InfoItems title="Mirada más allá..." body="Somos intuitivos e innovadores."></InfoItems>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col>
                        <Link to="/contacto">
                            <Button className="btn-deal">Contáctenos</Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
