import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import steps from '../../../../images/steps.svg'
import { Link } from "react-router-dom"

export const Body1 = () => {
    return (
        <section className="steps-section">
            <Container>
                <Row className="text-center">
                    <Col>
                        <h3 paragraph={true}>Abra su cuenta de inversión en simples pasos y acceda al mercado de capitales desde su cuenta como Persona Física o Persona Jurídica.</h3>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col xs={12} md={{ span: 8, offset: 2 }} lg={{ span: 6, offset: 3 }}>
                        <a
                            href={"https://app.dealfs.com.ar/dfs/solicitud-cuenta.registro"}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img src={steps} alt="steps" className="w-100"></img>
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
