import React from 'react'
import { Container, Row, Col } from "react-bootstrap"

export const SalesNTradingPage = () => {
    return (
        <section className="inner-page-section">
            <Container>
                <Row>
                    <Col>
                        <div className="regular-page-header">
                            <h1>Brokerage</h1>
                            <p>Colocaciones de AR$ / USD (Cauciones, Cheques, Letras, Etc.), Titulos Públicos Soberanos y Provinciales, Bonos Corporativos (ON), Fideicomisos Financieros, Acciones, Derivados financieros (ROFEX), Acciones Externas (CEDEAR), Compra/venta de Dólares Bolsa.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
