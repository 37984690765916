import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { makeStyles, Typography, Button } from "@material-ui/core"
import { Form, Field } from "react-final-form"
import emailjs from "emailjs-com"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import PhoneIcon from "@material-ui/icons/Phone"
import minibar from "../../../../images/minibar.png"
import comisionesPdf from '../../../../images/archivos/comisiones.pdf'

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "100%",
    },
  },
}))

export const Comisiones = () => {
  return (
    <Container id="comisiones" className="mb-5">
      <Row className="mt-5 pt-5 text-center">
        <Col>
          <Typography className="dfs-title" paragraph={true}>
            Comisiones
          </Typography>
          <object width="100%" height="750" data={comisionesPdf} type="application/pdf">   </object>
        </Col>
      </Row>
    </Container>
  )
}
