import React from "react"
import logo1 from "../../../../images/partners/1.png"
import logo2 from "../../../../images/partners/2.png"
import logo3 from "../../../../images/partners/3.png"
import logo4 from "../../../../images/partners/4.png"
import logo5 from "../../../../images/partners/5.png"

export const Partners = () => {
  return (
    <section className="partners-logos">
      <div className="logo-holder">
        <img src={logo1} alt="" />
      </div>
      <div className="logo-holder">
        <img src={logo2} alt="" />
      </div>
      <div className="logo-holder">
        <img src={logo3} alt="" />
      </div>
      <div className="logo-holder">
        <img src={logo4} alt=""  />
      </div>
      <div className="logo-holder">
        <img src={logo5} alt="" />
      </div>
    </section>
  )
}
