import * as React from "react"
import LinkedInIcon from "@material-ui/icons/LinkedIn"

export default function BioCard(props) {
  const { nombre, puesto, bio, cargos, link, imagen } = props

  const divStyle = {
      backgroundImage: 'url('+imagen+')'
  }

  return (
    <div className="biocard-nosotros">
      <div className="nosotros-img" style={divStyle}></div>

      <div className="biocard-body">
        <h2>{nombre}</h2>
        <h5>{puesto} </h5>
        <p>
          {bio}
        </p>
        {
          cargos ? 
          <p>
            {cargos.map((cargo) => (
                <li>{cargo}</li>
              ))}
          </p>
        :
          <></>
        }

        {
          link ? 
          <a href={link} target="_blank" rel="noreferrer">
            <LinkedInIcon />
          </a>
        :
          <></>
        }

      </div>
    </div>
  )
}
