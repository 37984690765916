import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';


export default function Mosaic(props) {
  const { title, body, link, image, orientation } = props

  return (
    <Container fluid className={ `mosaic-section ${orientation}` }>
      <Row>
          <Col xs={12} lg={6} className="mosaic-info" >
            <div class="mosaic-inner-info">
              {
              title ? 
                <h3>
                  {title}
                </h3>
              :
                <></>
              }
              {
                body ? 
                <p>
                  {body}
                </p>
              :
                <></>
              }
              {
                link ? 
                <Link to="/nosotros">
                    <Button className="btn-deal">Conozca Más</Button>
                </Link>
              :
                <></>
              }
            </div>
          </Col>
          <Col xs={12} lg={6} className="mosaic-img" style={{ backgroundImage: `url(${image})` }}>
          </Col>
      </Row>
  </Container>
  );
}