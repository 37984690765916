import React from 'react'
import empresa from './../../../../images/porque_elegirnos.jpg'
import { Mosaic } from '../../../elements'

export const PorqueElegirnos = () => {

    return (
        <Mosaic orientation="regular" link="/nosotros" image={empresa} title="¿Por qué elegirnos?" body="En DFS asesoramos a nuestros clientes con el objetivo de mantener y acrecentar sus ahorros. Deal Financial Services se enorgullece de abrir una nueva etapa de expansión y crecimiento a partir de 2016, invitando a los clientes individuales y a nuevos segmentos de clientes (instituciones, corporaciones y pymes) a sumarse nuestra propuesta de valor y también ampliando la oferta de servicios y productos financieros."></Mosaic>
    )
}
