import React from 'react'
import { Typography } from "@material-ui/core"
import { Container, Row, Col } from "react-bootstrap"
import { SimpleCard } from "../../Components/elements"
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp"
import ViewModuleIcon from "@mui/icons-material/ViewModule"

export const FinanciamientoPage = () => {
    return (
        <section className="inner-page-section">
            <Container>
                <Row>
                    <Col>
                        <div className="regular-page-header">
                            <h1>Financiamiento</h1>
                            <p>Originación, emisión y colocación de pagarés bursátiles y mutuos dollar linked, con y sin garantía.</p>
                            <p>Emisión primaria y negociación de Cheques de Pago Diferido, con y sin garantía.</p>
                            <p>Financiamiento de Pymes vía Sociedades de Garantía Recíproca.</p>
                            <p>2° en Ranking MAV Ene-Dic 2021.</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4 pt-4">
                    <Col>
                        <SimpleCard link="https://app.dealfs.com.ar/dfs/app.simulacion-operacion">
                        <Typography variant="h5">
                            <ViewModuleIcon color={"info"} fontSize={"large"} />{" "}
                            Calculadora de rendimientos
                            <ArrowForwardIosSharpIcon className="float-right" />
                        </Typography>
                        </SimpleCard>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
