import React from "react"
import Typography from "@mui/material/Typography"
import { Container, Row, Col } from "react-bootstrap"
import Button from "@mui/material/Button"
import { SimpleCard } from "./../elements"
import instrumentosMock from "../../__mocks__/instrumentos"

export const Cards = () => {
  return (
    <Container className="cards-products-style">
      <Row className="mt-4">
        <Col>
          <h2 className="inner-section-title">Instrumentos de Inversión</h2>
        </Col>
      </Row>
      <Row>
        {instrumentosMock.map((x) => (
          <Col xs={12} sm={4} className="mt-4 mb-4">
            <SimpleCard link={x.link} height={315}>
              <Typography paragraph={true} className="card-body">
                <Typography className="card-title-1" paragraph="true">
                  {x.title}
                </Typography>
                {x.body}
                <br />
                <Button>
                  ㅤMás Infoㅤ
                </Button>
              </Typography>
            </SimpleCard>
          </Col>
        ))}
      </Row>
    </Container>
  )
}
