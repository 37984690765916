import React from 'react'
import { Comisiones } from '../Components/Home/Body/Comisiones'

export const ComisionesPage = () => {
    return (
        <>
            <Comisiones/>
        </>
    )
}
