import React from "react"
import { Typography } from "@material-ui/core"
import { SimpleCard } from "./../elements"
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"

import familiaDeFondosMock from "../../__mocks__/familiaDeFondos"
import { Container, Row, Col } from "react-bootstrap"
import productos from "../../images/productos-02.jpeg"

export default function Fondos() {
  return (
    <section className="inner-page-section">
      <Container>
        <Row>
          <Col>
            <div className="regular-page-header">
              <h4>Servicios / <Link to="/servicios/asset-management">Asset Management</Link></h4>
              <h1>Nuestra familia de Fondos</h1>
            </div>

            <Typography className="blue-text" paragraph="true">
              Familia de Fondos que usted puede elegir.
            </Typography>
          </Col>
        </Row>

        <Row>
          {familiaDeFondosMock.map((x) => (
            <Col xs={12} sm={4} className="mt-1 mb-2 pb-4 my-auto">
              <SimpleCard color="blue" className="text-center" link={x.link}>
                <Typography className="card-title-1" paragraph={true}>
                  {x.title}
                </Typography>
                <Typography className="white" paragraph={true}>
                  {x.body}
                </Typography>
                <br />
                <Button variant="outlined">Más Info</Button>
              </SimpleCard>
            </Col>
          ))}
        </Row>
        <Row className="mt-5 mb-5 text-center">
          <Col>
            <div class="familia-fondos-graph">
              <img
                src={productos}
                alt="productos"
                className="workmap-img"></img>

              <Link to="/servicios/liquidez" className="link-to-liquidez"></Link>
              <Link to="/servicios/renta-fija" className="link-to-renta-fija"></Link>
              <Link to="/servicios/renta-variable" className="link-to-renta-var"></Link>
            </div>
          </Col>
        </Row>
        <Row className="return-btn-row">
          <Col>
            <Link to="/servicios/asset-management"><Button className="btn-deal btn-deal-blue">Volver a Asset Management</Button></Link>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
