
export const FamiliaDeFondosMock = [
    {
        title:"Familia de Fondos que usted puede elegir.", 
        rows: 
            [
                {
                    title: "Money Market",
                    fondo: "IAM Ahorro Pesos",
                    tipo: "Liquidez",
                    plazo: "Inmediata",
                    moneda: "ARS",
                    perfilInversor:"Conservador",
                    inversionMinima: "$1.000",
                    horizonte: "Corto Plazo",
                    objetivo: "Brindar liquidez inmediata, con un rendimiento intermedio entre una caja de ahorro y un depósito a plazo fijo.",
                    //objetivo: "Preservar el capital con un rendimiento superior al de los fondos comunes de inversión de plazo fijo en pesos, otorgando un alto nivel de rentabilidad corriente en pesos invirtiendo en instrumentos de mejor calidad crediticia dentro del contexto argentino.",
                    // cartera: "Fondos de Money Market, Plazos Fijos, Letras del Banco Central y del Gobierno de la Ciudad de Buenos Aires, Cheques Garantizados por SGR y Fideicomisos Financieros.",
                    cartera: "Ctas remuneradas, cauciones y plazos fijos.",
                    agenteAdministracion: "Industrial Asset Management",
                    agenteCustodia: "Banco Industrial",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EcOtYGNohfxPm41QSA-b_2ABCCRArBBLew3P_XovAm1_Fg?e=ZAsgkB",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EYYlTZx5KrxGv7Kfnczc8Z8BmtVqrIDS6S3v_84b2pMlxg?e=Rx8AM5"

                },
                {
                    title: "Ahorro",
                    fondo: "CMA Protección",
                    tipo: "Liquidez",
                    plazo: "24hs",
                    moneda: "ARS",
                    perfilInversor:"Conservador",
                    inversionMinima: "$1.000",
                    horizonte: "Corto Plazo",
                    objetivo: "Brindar un rendimiento superior al de los fondos de money market, otorgando un alto nivel de liquidez y rentabilidad corriente en pesos invirtiendo en instrumentos de calidad crediticia dentro del mercado argentino.",
                    cartera: "Plazos Fijos, Letras del Banco Central, del Tesoro y del Gobierno de la Ciudad de Buenos Aires, Cheques Garantizados por SGR y Fideicomisos Financieros.",
                    agenteAdministracion: "CMA Asset Management",
                    agenteCustodia: "Banco Comafi",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EcQPehfrt2pLjUcLBUID-wsB0Y5i7BqEZwNUOe9S27XXcg?e=AlpKyR",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EZ64TTqrQghNiOtQFwavo3cBDWm3BDfEe6MAr5mh71Nv4g?e=3vdswP"
                }
            ]
    }
]
