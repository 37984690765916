import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import empresa from '../../../../images/sobre_nosotros.jpg'

export const SobreNosotros = () => {

    return (
        <Container fluid className="mosaic-section invertido">
            <Row>
                <Col xs={12} lg={6} className="mosaic-info" >
                    <div class="mosaic-inner-info">
                        <h3>Sobre nosotros</h3>
                        <p>Somos una compañía de servicios financieros, integrada por un equipo de profesionales con amplia trayectoria en el mercado financiero local e internacional.</p>
                        <ul>
                            <li><strong>ALYC:</strong> Servicio de intermediación de todos los activos financieros con oferta pública.</li>
                            <li><strong>ACDI:</strong> Seleccionamos los mejores de Fondos Comunes de Inversión, que se ajusten a las necesidades de cada cliente.</li>
                            <li><strong>Asesor de Inversión:</strong> Somos asesores de Inversión en los Fondos CMA Renta Dólar, CMA Acciones y CMA Abierto Pymes.</li>
                        </ul>
                        <Link to="/nosotros">
                            <Button className="btn-deal">Conozca Más</Button>
                        </Link>
                    </div>
                </Col>
                <Col xs={12} lg={6} className="mosaic-img" style={{ backgroundImage: `url(${empresa})` }}>
                </Col>
            </Row>
        </Container>
    )
}
