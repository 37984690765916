
export const AccionesMock = [
    {
        title:"Familia de Fondos que usted puede elegir.", 
        rows: 
            [
                {
                    title: "Fondo CMA Acciones – DFS Asesor de Inversiones",
                    fondo: "Fondo CMA Acciones – DFS Asesor de Inversiones",
                    tipo: "Acciones",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Agresivo",
                    inversionMinima: "$1.000",
                    horizonte: "Largo Plazo",
                    objetivo: "Lograr la apreciación del capital a largo plazo invirtiendo en instrumentos de renta variable. El objetivo del Fondo es tener exposición a compañías con perfil exportador o presencia regional y management competitivo.",
                    cartera: "Acciones y CEDEARs",
                    agenteAdministracion: "CMA Asset Management",
                    agenteCustodia: "Banco de Valores",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EaVAz3txlT5EgK2Tmik3uHUBnzIINP-969jUgJbJ3930Ng?e=RXhDgY",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EWhEE9cLEs9KlFwkI6YFV0EBJDaJy-z_9z9mA0Gy0sWPxw?e=T57LqO"

                },
                {
                    title: "Fondo ST Soja",
                    fondo: "Fondo ST Soja",
                    tipo: "Commodities",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Agresivo",
                    inversionMinima: "$1.000",
                    horizonte: "Largo Plazo",
                    objetivo: "Obtener un rendimiento similar al valor de la Soja Rosario.",
                    cartera: "Futuros de Soja.",
                    agenteAdministracion: "Southern Trust Soc Gerente",
                    agenteCustodia: "Banco Comafi",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EcQtgqro1whMkV-Wtjnyuy0BmIG45NyCmBsqIPj6s-JkSw?e=goFWKl",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EdUfAhd61NxGvQ7LJ_u5MKYBvQF-Ksn1yumlrGkwQ4trcQ?e=XfzaDR"
                },
            ]
    }
]
export const RentaInternacionalMock = [
    {
        title:"Renta Internacional", 
        rows: 
            [
                {
                    title: "Fondo CMA Renta Dólar",
                    fondo: "Fondo CMA Renta Dólar",
                    tipo: "Dólar",
                    plazo: "48hs",
                    moneda: "USD MEP",
                    perfilInversor:"Moderado",
                    inversionMinima: "US$1.000",
                    horizonte: "Largo Plazo",
                    objetivo: "Su objetivo es proteger el capital de la depreciación del peso y obtener una renta en dólares con un horizonte de inversión medio, y liquidez diaria.",
                    cartera: "Créditos soberanos y corporativos de latinoamérica y USA y hasta 50% en bonos corporativos de Argentina",
                    agenteAdministracion: "CMA Asset Management",
                    agenteCustodia: "Banco de Valores",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/ESIqk2ohSLhJsid1kOquUsgBhVPM1u4XFNUF7ri0B-OjSg?e=zzXEzf",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/Eftzb6ov4F1Djbz1FYRs0tgB8nuIoyxZm7a5iEmqxSirZA?e=hZXTUk"

                }
            ]
    }
]

export const CoberturaInflacionMock = [
    {
        title:"Cobertura Inflación", 
        rows: 
            [
                {
                    title: "Fondo Balanz Institucional",
                    fondo: "Fondo Balanz Institucional",
                    tipo: "CER",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Obtener rendimientos similares o superiores a la inflación en el mediano plazo. Ideal para inversores que busquen rendimientos variables que sigan a la inflación y que tengan un horizonte de inversión de mediano plazo.",
                    cartera: "Títulos públicos y privados ajustados por CER y/o UVA",
                    agenteAdministracion: "Balanz Soc Gerente",
                    agenteCustodia: "Banco de Valores",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EfvZhr626ENEmaqZFaUprdwBOs-Bbvf_qRFJR4IhyXD5Dg?e=oDxswA",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/ESiciEWAC6VHk7j_BgGWkYkBp7JGD5raXNeBWitO8ZvHnw?e=ULbFzn"

                }
            ]
    }
]

export const CoberturaTipoCambioMock = [
    {
        title:"Cobertura Tipo de Cambio Oficial", 
        rows: 
            [
                {
                    title: "CMA Performance",
                    fondo: "CMA Performance",
                    tipo: "Dollar Linked",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Largo Plazo",
                    objetivo: "Obtener un desempeño similar al tipo de cambio oficial. El fondo invierte en una cartera diversificada de títulos públicos y privados que ajusten su capital por la evolución del tipo de cambio oficial, o bien realizando cobertura de las inversiones en pesos a través de futuros de tipo de cambio. Si bien se busca que el fondo tenga una alta correlación con el tipo de cambio, puede no representar una cobertura perfecta.",
                    cartera: "Títulos públicos y privados con cláusula de ajuste al tipo de cambio oficial así como en títulos en pesos de corto plazo con cobertura de futuros de tipo de cambio.",
                    agenteAdministracion: "CMA Asset Management",
                    agenteCustodia: "Banco Comafi",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EeprdwCSNN1Bog8v9m33AFwBx1AcoDRJjwHia_93P6zwTw?e=klDyOn",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EcGKtgdH6dZEpaYFvfmFqZgB2hlGdixsXOPurHIEO6Yh1Q?e=Fq7gcF"

                },
                {
                    title: "IAM Renta Balanceada",
                    fondo: "IAM Renta Balanceada",
                    tipo: "Dollar Linked",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Moderado",
                    inversionMinima: "$1.000",
                    horizonte: "Mediano Plazo",
                    objetivo: "Obtener un desempeño similar al tipo de cambio oficial. El fondo invierte en una cartera diversificada de títulos públicos y privados que ajusten su capital por la evolución del tipo de cambio oficial, o bien realizando cobertura de las inversiones en pesos a través de futuros de tipo de cambio. Si bien se busca que el fondo tenga una alta correlación con el tipo de cambio, puede no representar una cobertura perfecta.",
                    cartera: "Títulos públicos y privados con cláusula de ajuste al tipo de cambio oficial así como en títulos en pesos de corto plazo con cobertura de futuros de tipo de cambio.",
                    agenteAdministracion: "Industrial Asset Management",
                    agenteCustodia: "Banco Industrial",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EfekZ9FsSplMqqh3wjJkir4BOemFUxOa1LgHZTfAQqXlpA?e=DuWHB9",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EdxH_8q3O0dItbuh9dfa8CEB-9oYDPbbJ5ENoI9ezux84A?e=saHNOu"
                },
                {
                    title: "Fondo ST Soja",
                    fondo: "Fondo ST Soja",
                    tipo: "Commodities",
                    plazo: "48hs",
                    moneda: "ARS",
                    perfilInversor:"Agresivo",
                    inversionMinima: "$1.000",
                    horizonte: "Largo Plazo",
                    objetivo: "Obtener un rendimiento similar al valor de la Soja Rosario.",
                    cartera: "Futuros de Soja.",
                    agenteAdministracion: "Southern Trust Soc Gerente",
                    agenteCustodia: "Banco Comafi",
                    linkInforme: "https://dealfs.sharepoint.com/:b:/s/Website/EcQtgqro1whMkV-Wtjnyuy0BmIG45NyCmBsqIPj6s-JkSw?e=goFWKl",
                    linkRG: "https://dealfs.sharepoint.com/:b:/s/Website/EdUfAhd61NxGvQ7LJ_u5MKYBvQF-Ksn1yumlrGkwQ4trcQ?e=XfzaDR"
                },
                
            ]
    }
]
