import React from 'react'

export const IngresarPage = () => {
    return (
        <div className="apertura-holder">
            <iframe 
                className="iframe-cuenta"
                title="Ingresar a mi cuenta"
                width="100%"
                height="500"
                src="https://app.dealfs.com.ar/dfs/solicitud-cuenta.inicio-sesion">
            </iframe>
        </div>
    )
}
