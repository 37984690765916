import React from 'react'
import { Container, Row, Col } from "react-bootstrap"

export const ResearchInformesPage = () => {
    return (
        <section className="inner-page-section">
            <Container>
                <Row>
                    <Col>
                        <div className="regular-page-header">
                            <h1>Nuestros reportes</h1>
                            <p>Estrategia Global y Local: Carteras recomendadas.</p>
                            <p>Monitor de Cedears: Cartera recomendada.</p>
                            <p>Reportes de resultados trimestrales de compañías. Recomendaciones.</p>
                            <p>Ranking semanal de Fondos Inversión.</p>
                            <p>Informe Mensual de Estrategia de Fondos.</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}
